import {
  CategoryEntity,
  GetCategoriesQueryResult,
  GetProductsQueryResult,
  Maybe,
  ProductsWithCountSchema,
  useGetCurrentUserQuery,
  useGetProductsLazyQuery,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Divider,
  Pagination,
  styled,
  Typography,
} from '@mui/material';
import { AliasBoolTypes } from 'types/baseTypes';
import { useDebounce } from 'utils';
import { Catalog, MultiSearch } from 'components';
import { client } from 'graphql/client';
import { GET_CATEGORIES, GET_PRODUCTS } from 'graphql/queries';
import { useTranslation } from 'next-i18next';
import { GetServerSideProps } from 'next';
import { AllProdIcon } from 'components/icons';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { MetaLayout } from 'components/Layout/MetaLayout/MetaLayout';
import { useSiteIdContext } from 'context/SiteIdContext';
import { getTranslations } from 'utils/i18n-cache';
import { hasAuthToken } from '../utils/authToken';

interface TransformFuncReturn {
  value: string;
  label: string;
  icon: React.ReactNode;
  targetIcon: React.ReactNode;
  svgCode: Maybe<string> | undefined;
}

interface HomePageProps {
  allCategories: GetCategoriesQueryResult;
  products: GetProductsQueryResult;
  locale: string;
}

export default function Home(props: HomePageProps) {
  const { siteId } = useSiteIdContext();
  const { isENLang } = useCurrentLanguage();
  const [countriesValue, setCountriesValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState('');
  const [countPagination, setCountPagination] = useState(0);
  const [limits] = useState(12);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const [query, { data: newProductsData, loading }] = useGetProductsLazyQuery();
  const user = useGetCurrentUserQuery({ skip: !hasAuthToken() });

  const searchTextDebounce = useDebounce(searchText);

  useEffect(() => {
    if (!user.error && user.data?.getCurrentUser?.country) {
      setCountriesValue(user.data.getCurrentUser.country);
    }
  }, [user]);

  useEffect(() => {
    query({
      variables: {
        input: {
          limit: limits,
          search: searchTextDebounce,
          categoryId: category ? +category : null,
          country: countriesValue,
          offset: limits * (page - 1),
          siteId: Number(siteId),
        },
      },
      fetchPolicy: 'network-only',
    });
  }, [
    category,
    limits,
    query,
    searchTextDebounce,
    page,
    countriesValue,
    siteId,
  ]);

  const allCategoriesData = props.allCategories.data?.getCategories.categories;

  const transformCategoriesData = (
    isEn: boolean,
    data?: CategoryEntity[],
  ): TransformFuncReturn[] => {
    const res = [
      {
        value: '',
        label: 'All Categories',
        icon: <AllProdIcon />,
      },
    ] as TransformFuncReturn[];

    data &&
      data.forEach((category: CategoryEntity) => {
        const src = `${process.env.NEXT_PUBLIC_BASE_URL}/uploads/images/categories/${category.icon}`;
        const srcTarget = `${process.env.NEXT_PUBLIC_BASE_URL}/uploads/images/categories/target_${category.icon}`;

        res.push({
          value: category.id,
          label: category[isEn ? 'nameEN' : 'nameAR'] as string,
          icon: category.icon ? src : null,
          targetIcon: category.icon ? srcTarget : null,
          svgCode: category.svgCode,
        });
      });

    return res;
  };

  // todo: remove
  // @ts-ignore
  const categories = transformCategoriesData(isENLang, allCategoriesData);

  const catalogData = props?.products?.data?.getProducts;
  const newCatalogData = newProductsData?.getProducts;

  useEffect(() => {
    if (newCatalogData) {
      setCountPagination(Math.ceil(newCatalogData?.count / limits));
    }
  }, [limits, newCatalogData, newCatalogData?.count]);

  return (
    <MetaLayout title={'Web Market'}>
      <Wrapper>
        <ContentWrapper>
          <NavBlock>
            <NavColumn>
              {categories.map((item, index) => {
                const isCategory = category === item.value;
                const svgString = item.svgCode || '';

                return (
                  <NavRow
                    key={index}
                    onClick={() => handleFilterClick(item.value)}
                  >
                    <NavIconBlock>
                      {item.icon ? (
                        !(item.icon as any)['$$typeof'] ? (
                          <WrapperAllCategoriesIcon
                            target={isCategory ? 'true' : 'false'}
                            dangerouslySetInnerHTML={{ __html: svgString }}
                          />
                        ) : (
                          <WrapperAllCategoriesIcon
                            target={isCategory ? 'true' : 'false'}
                          >
                            {item.icon}
                          </WrapperAllCategoriesIcon>
                        )
                      ) : (
                        <Box sx={{ width: '24px', height: '24px' }} />
                      )}
                    </NavIconBlock>

                    <Typography
                      variant={'average600'}
                      component={'p'}
                      color={isCategory ? '#4663F4' : ''}
                    >
                      {item.label}
                    </Typography>
                    {/*! when you need the current component just uncomment this code*/}
                    {/*{item.new && <BadgeCustom marginInlineStart={'56px'} />}*/}
                  </NavRow>
                );
              })}
            </NavColumn>
          </NavBlock>

          <Divider orientation={'vertical'} />

          <Content>
            <MultiBlock>
              <MultiSearch
                loading={false}
                countriesValue={countriesValue}
                textValue={searchText}
                categoryFilterValue={category}
                categoryFilterData={categories}
                placeholderTextField={`${t('app.product_search')}...`}
                setCategoryFilterData={handleFilterClick}
                setTextValue={handleSearchText}
                setCountriesValue={handleCountryChange}
              />
            </MultiBlock>

            <Catalog
              loading={loading}
              data={(newCatalogData || catalogData) as ProductsWithCountSchema}
            />

            <PaginationRowCSS>
              {countPagination > 1 && (
                <Pagination
                  count={countPagination}
                  shape='rounded'
                  color='primary'
                  page={page}
                  onChange={handlePaginationChange}
                />
              )}
            </PaginationRowCSS>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </MetaLayout>
  );

  function handleFilterClick(value: string) {
    setCategory(value);
    resetPagination();
  }

  function handleSearchText(value: string) {
    setSearchText(value);
    resetPagination();
  }

  function handleCountryChange(country: string) {
    setCountriesValue(country);
    resetPagination();
  }

  function handlePaginationChange(
    event: React.ChangeEvent<unknown>,
    value: number,
  ) {
    setPage(value);
  }

  function resetPagination() {
    setCountPagination(0);
    setPage(1);
  }
}

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'en',
}) => {
  try {
    const [allCategories, products] = await Promise.all([
      client.query<GetCategoriesQueryResult>({
        query: GET_CATEGORIES,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            limit: Infinity,
            offset: 0,
          },
        },
      }),
      client.query<GetProductsQueryResult>({
        query: GET_PRODUCTS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            limit: 12,
            categoryId: null,
            offset: 0,
            search: null,
            country: null,
          },
        },
      }),
    ]);

    return {
      props: {
        ...(await getTranslations(locale)),
        allCategories,
        products,
        locale,
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '1240px',
  alignSelf: 'center',
  width: '100%',
  padding: '0 20px',
  [theme.breakpoints.down('laptop')]: {
    padding: '0',
  },
}));

export const NavBlock = styled(Box)(({ theme }) => ({
  paddingTop: '86px',
  paddingBottom: '86px',
  // paddingInlineStart: '123px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

interface NavIconBlockProps extends BoxProps {
  target?: AliasBoolTypes;
}

export const NavIconBlock = styled(Box)(({ target }: NavIconBlockProps) => {
  return {
    display: 'flex',
    svg: {
      path: {
        fill: target === 'true' && '#4663F4',
      },
    },
  };
});

export const NavColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '276px',
  gap: '29px',
}));

export const NavRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '70px',
  paddingBottom: '70px',
  // paddingInlineEnd: '123px',
  paddingInlineStart: '30px',
  width: '100%',
  flexDirection: 'column',
  minHeight: '547px',

  [theme.breakpoints.down('laptop')]: {
    minHeight: 'auto',
    padding: '20px 20px 24px 20px',
    overflow: 'hidden',
  },
}));

export const MultiBlock = styled(Box)(({ theme }) => ({
  marginBottom: '30px',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '16px',
  },
}));

interface WrapperAllCategoriesIconProps extends BoxProps {
  target?: AliasBoolTypes;
}

export const WrapperAllCategoriesIcon = styled(
  Box,
)<WrapperAllCategoriesIconProps>(({ target }) => ({
  display: 'flex',
  svg: {
    path: {
      fill: target === 'true' ? '#4663F4' : '#96A0B5',
      transition: 'fill 0.2s linear',
    },
  },
}));

export const PaginationRowCSS = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '50px',
  direction: 'ltr',
  [theme.breakpoints.down('laptop')]: {
    marginTop: '25px',
  },
}));

interface ImageItemCSSProps extends BoxProps {
  target?: AliasBoolTypes;
}

export const ImageItemCSS = styled(Box)<ImageItemCSSProps>(({ target }) => ({
  display: target === 'true' ? 'none' : 'flex',
  alignItems: 'center',
}));
